import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const Folie = () => (
	<div>
		<Helmet title="Fólie a obalové materiály" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Fólie a obalové materiály</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Fólie a obalové materiály</li>
					</ul>
				</div>
				<p><em>PET – se uplatnil především při výrobě vláken
				vynikajících nemačkavostí a malou navlhavostí (např. Dacron; na
				textilních výrobcích můžeme najít nesprávnou zkratku PES, která
				označuje příslušnost tohoto polymeru k polyesterům), lahví (obvykle
				označovaných jen zkratkou PET) a dalších obalů a fólií. Tenké fólie
				jsou často používány pod obchodním názvem Mylar. Znám je pod řadou
				dalších obchodních názvů, např. Arnite, Impet, Rynite, Ertalyte,
			Hostaphan, Melinex, Dacron, Terylene, Trevira atd.</em></p>
				<p>V závislosti na procesu výroby a zpracování (rychlosti chladnutí
				taveniny) je možno připravit téměř amorfní PET (průhledný) a
				polokrystalický PET (mléčně zakalený). Připravuje se polykondenzační
				reakcí (esterifikací) ethylenglykolu (ethan-1,2-diolu) s kyselinou
			tereftalovou.</p>
				<p>Tenolan – Biaxiálně orientované polyesterové fólie jsou jedním
				z nejkvalitnějších a nejpoužívanějších materiálů pro výrobu
			flexibilních obalů.</p>
				<p>Fólie Tenolan jsou vhodné pro balení potravin (provedení N), ale
			běžně se používají i pro balení technických produktů.</p>
				<p><h4><em>PE</em></h4> – Je odolný vůči kyselinám i zásadám,
				použitelný do teploty kolem 80 stupňů Celsia. Vyrábí se z něj
				smrštitelné folie, roury, ozubená kola, ložiska, textilní vlákna,
			nejrůznější hračky, sáčky (mikroten) a elektrotechnická izolace.</p>
				<p>Rozlišují se dva základní druhy polyethylenu: LDPE (s nízkou hustotou)
			a HDPE (s vysokou hustotou).</p>
				<p>V poslední době se HDPE používá při výrobě kompozitního materiálu
				na bázi dřeva – woodplastic, který se používá jako náhrada dřeva
				v mnoha oborech. Polyethylen je v současnosti nejpoužívanějším polymerem
				na světě. Jeho zásluhou předčila již v roce 1979 produkce plastů
				celosvětovou výrobu oceli. Jeho roční produkce je odhadována na více než
			60 milionů tun.[</p>
				<p><h4><em>AL</em></h4> – Přestože hliník patří mezi prvky
				nejvíce zastoupené v zemské kůře, patřila jeho průmyslová výroba do
				ještě poměrně nedávné doby k velmi obtížným procesům. Je to
				především z toho důvodu, že elementární hliník nelze
				jednoduše meta­lurgicky vyre­dukovat z jeho rudy jako
				např. železo kok­sem ve vysoké peci. Teprve zvládnutí
				průmyslové elek­trolýzy taveni­ny kovových rud umožnilo současnou
			mnohasettunovou roční produkci čistého hliníku.</p>
				<p>Při elektrolýze se z taveniny směsi předem
				přečištěného bau­xitu a kryoli­tu o teplotě asi 950 °C
				na katodě vylučuje elementární hliník,
				na grafitové a­nodě vzniká kys­lík, který ihned reaguje
				s materiáleme­lektrody za vzniku toxického plynného oxidu
			uhelnatého, CO.</p>
				<p><h4><em>PAP</em></h4> – Papír <em>je tenký, hladký materiál
				vyráběný zhutněním vlákna. Použitá vlákna jsou obvykle přírodní a
				založená na celulóze. Nejobvyklejší materiál je buničina vy­robená
				ze dřeva (většinou smrk), či ze sekundárních vláken (sběrový papír),
				ale mohou být použity i jiné rostlinné vláknité materiály
			jako bavlna, a konopí.</em></p>
				<p><h4><em>PA</em></h4> – Polyamidová vlákna <em>(meziná­rodní
				zkratka: PA) jsou to vlákna z lineárních ma­kromolekul, v jejichž
				řetězcích se opakují funkční amido­vé skupiny, výchozí materiál
			je ropa, ze které vznikají chemickým procesem základní suroviny</em></p>
				<p><h4><em>PP</em></h4> – Polypropylen
					<em>je termoplastic­ký polymer ze skupiny polyolefinů, které patří mezi
				nejběžnější plasty, používá se v mnoha odvětvích potravinářského a
				textilního průmyslu a v laboratorních vybaveních. olypropylen má podobné
				fyzikálně-chemické vlastnosti jako polyetylen. Křehne při nízkých
				teplotách, kolem 140–150 stupňů Celsia měkne, kolem 160–170 stupňů
				Celsia se taví. Je odolný vůči olejům, organickým rozpouštědlům
				a alkoholům, dobře rozpouští se v xylenech či te­trahydronafta­lenu.
			Polypropylen vyniká velmi dobrou chemickou a mechanickou odolností.</em></p>
				<p><h4><em>Ekologické folie</em></h4> – <em>Nezbytným prvkem pro
				výrobu ekologických fólií jsou oxo-biodegradabilní přísady (OBP), které
				změní chování plastových fólií tak, že postupně degradují
				„rozpadají se“. Přísady tak napomáhají urychlit životní cyklus
				hlavního materiálu. Plastové fólie, vyrobené s použitím OBP přísad,
				degradují v podmínkách působení slunečního UV záření, vlhkosti a
				mechanického tlaku. Oxo-biodegradabilní přísady (OBP) lze doplnit do výroby
			HDPE i LDPE fólií.</em></p>
				<ul>
					<li>Materiál může být „naprogramován“ tak, aby poskytoval různou
					délku životnosti fólie, a to v závislosti na požadavcích na výrobek.
					Délku rozpadu lze zvolit od 3 do 12 měsíců. Proces oxo-degradace pak
					začne okamžikem působení výše popsaných mechanicko-fyzikálních vlivů
					na fólii či produkt z ní vyrobený. Tedy např. ekologické tašky,
					skladované v původním balení (v krabici) nepodléhají rozkladu. Vlastní
					proces rozpadu je zahájen až v okamžiku vybalení, tedy za působení
					denního světla, mechanického pnutí a vlhkosti – běžným
				užitím tašky.</li>
					<li>Fólie s přísadami OBP lze zpracovávat stejným způsobem, na stejném
				výrobním zařízení jako běžné polyetylenové fo­lie.</li>
					<li>Technologie je kompatibilní se všemi metodami skládkování,
				kompostování, recyklace a spalování.</li>
					<li>Polymery OBP jsou ekologicky příznivé. Výsledným produktem po degradaci
					je CO2, voda a minerální látky. Oxo-biodegradací OBP nevzniká metan a
				nezanechává fragmenty plastů v půdě.</li>
					<li>Přísady OBP prošly eko-toxikologickými testy, jako např. klíčivost
					semen, růst rostlin a přežití organismů, provedenými dle normy British
				National Standards ON S 2200 a ON S 2300.</li>
					<li>Výrobky používající tuto technologii jsou plně ve shodě se
					stávajícími požadavky evropských i amerických institucí Food &amp; Drug
				Administration na kontakt s potravinami.</li>
					<li>Technologii lze uplatnit pro většinu aplikací plastů od balení
					potravin, přes nákupní tašky či zemědělské fólie až po krycí fólie
				ve stavebnictví i medicínské aplikace.</li>
				</ul>
				<p />
				<p><h4><em>Pegatex®</em></h4>  S - <em>(spunbond) je netkaná
				textilie vyrobená technologií spunbond (S) z polypropylenu. Dodává se
				v různýchbarevných odstínech. Základní vlastností tohoto typu netkané
				textilie jsou bariérové vlastnosti, kterých se využívá pro výrobu
				produktů jedno­rázového užití. Tento typ netkané textilie se uplatňuje
				v široké řadě aplikací od výroby hygienických produktů přes
			zemědělství až po stavebnictví či automobilový průmysl.</em></p>
				<p><h4><em>Pegatex®  Micro</em></h4> - <em>je netkaná textilie
				typu spunbond vy­robená ze speciálního (metalocenního) polypropylenu a
				mezi její základní charakteristiky patří velmi jemná vlákna, lepší
				mechanické vlastnosti, lepší textilní charakter a výborná rovnoměrnost.
				Příznivý dojem můžeme získat již při hodnocení omaku. Textilie
				z jemných vláken má výrazně měkčí, jemnější omak. Vnitřní
				struktura jemných vláken má výrazně orientovanější charakter, což
			přináší zvýšení pevnosti v tahu.</em></p>
				<p><h4>Permanentně antistatická fólie Běžné plastové fólie často
				generují elektrostatický výboj (ESD). Ten omezuje využitelnost těchto
				fólií jako obalového materiálu pro mnohé aplikace. Obvykle se jedná
				o balení výbušnin a balení výrobků v elektrotechnickém
			průmyslu.</h4></p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default Folie;