import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const BaleniPodOchrannouAtmosferou = () => (
	<div>
		<Helmet title="Balení pod ochrannou atmosferou" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Technologie balení - komodity</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Balení pod ochrannou atmosférou</li>
					</ul>
				</div>
				<p>Použití technických plynů v oblasti potravinářství je jeden
	z hlavních pilířů zachování kvality a přirozených vlastností potravin.
	Na první pohled se zdá, že neexistuje souvislost mezi technickými plyny a
	trvanlivostí potravin, ale jsou to právě tyto plyny a jejich směsi, které
	zaručují uchování chuti a barvy potravin, jejich aroma a zároveň
	zabraňují vzniku a rozvoji nežádoucích mikrobiálních zárodků.</p>

				<p>Prodloužení trvanlivosti se dosahuje snížením obsahu kyslíku a
	vytvoření atmosféry ochranné směsi plynů v balení. Ochranná atmosféra
	zároveň umožňuje uchovat barvu, chuť a tvar potravin.</p>

				<p>Pro, pekárenské výrobky, majonézy apod. se používá atmosféra
	složená ze směsi dusíku a oxidu uhličitého. Pro balení např. kávy a
	pražených arašídů je používán čistý dusík. Plyny jsou dodávány
	v tlakových láhvích různé velikosti a v případě vhodného profilu
	spotřeby plynu lze dlouhodobě pronajmout vyvíječ plynného dusíku, který
	dokáže ekonomicky vyrobit plyn přímo na místě spotřeby. </p>

				<p>Skladování v řízené atmosféře: zaručuje možnost skladovat a
	dodávat čerstvé produkty po celý rok.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default BaleniPodOchrannouAtmosferou;