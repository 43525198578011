import { Component } from 'preact';
import { Link } from 'preact-router/match';
import register from 'preact-custom-element';

// import style from './style';
// import menu from './../../assets/menu.svg';
// import logo from '../../assets/flying-eda2.svg';

const Hamburger = ({toggle}) => {
	return (
		<svg id="hamburger"
			class="hamburger"
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			onClick={toggle}
			>
			<title>Menu</title>
			<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
		</svg>
	)}


export default class Header extends Component {
	constructor(props){
		super(props);
		this.state = {
			toggle: false
		};
		this.navToggle = this.navToggle.bind(this);
	}
	navToggle(e) {
		// e.preventDefault();
		const menu = document.getElementById('nav');
		// const toggle = document.getElementById('toggle');

		// console.log(e.target.id);
		if (e.target.id === 'hamburger'){
			this.setState({ toggle: true });
		}

		const display = menu.style.display;

		if (display === 'none' || display === '' || display === undefined) {
			menu.style.display = 'block';
	    }
		else if (this.state.toggle) menu.style.display = 'none';
	}
	render(){
		return (
			<header class="header">
				<div id="toggle" class="toggle">
				{/* <img src={menu} class="nav_button" alt="Hamburger" onClick={this.toggle} /> */}
				<Hamburger id="hamburger" toggle={this.navToggle} />
					<div class="logo_desc">The best way to pack your product</div>
				</div>
				<nav class="menu" id="nav">
					<Link activeClassName="active" onClick={this.navToggle} href="/">Home</Link>
					<Link activeClassName="active" onClick={this.navToggle} href="/about/">Kdo jsme</Link>
					<Link activeClassName="active" onClick={this.navToggle} href="/technologie-vyroba/">Technologie a výroba</Link>
					<Link activeClassName="active" onClick={this.navToggle} href="/ceny/">ceny</Link>
					<Link activeClassName="active" onClick={this.navToggle} href="/kontakty/">kontakty</Link>
				</nav>
				<a class="header-home-link" href="/" >
				<div class="fronter_top">
					<span class="best">Best</span><span class="pack">Pack</span>
					<p class="fronter_description" >The best way to pack your product</p>
				</div>
				</a>
			</header>
		)
		;

	}

}
// register(Hamburger, 'nav-hamburger', ['toggle']);