import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const V250 = () => (
	<div>
		<Helmet title="Vertikální balící stroj V250" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Vertikální balící stroj V250</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Vertikální balící stroj V250</li>
					</ul>
				</div>
				<p>Vertikální balicí stroje jsou určeny pro <a
					href="/technologie-vyroba/balene-komodity/">balení komodit</a> do
			plochých sáčků a různých typů stojacích sáčků. <a href="/technologie-vyroba/sacky/" >Sáčky</a> stroj formuje z role fólie.
			Směr pohybu fólie po tubusu balicího stroje je vertikální – shora dolů.
			Vertikální balicí stroje pracují v krokovém nebo kontinuálním pracovním
		režimu a jsou konstruovány pro střední až vysoké výkony.</p>
				<h3>Charakteristika</h3>
				<p><strong>V-250</strong> je kompaktní, snadno ovldatelný stroj optimálních
			rozměrů, konstruovaný pro vysoký výkon plnění sáčků s kapacitou až
		do 6,5 litrů.</p>
				<p>Model <strong>V-450</strong> byl vyvinut s konceptem flexibility na
			pokrytí projektů, vyžadující plnění do velkých pytlů s velkou
			kapacitou a velkým objemem, s důrazem na zachování nízké
		výrobní ceny.</p>
				<p>Flexibilní variabilní cyklus, zajišťuje optimální účinnost,
		dostupnost a kvalitu všech funkcí i za horších pracovní podmínek.</p>
				<p>Nový systém tlakového ovládání čelistí, zaručuje optimální výkon
		stroje ve vztahu k formátu, materiálu a výrobků ve vztahu k dávce.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default V250;