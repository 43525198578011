import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const BaleneKomodity = () => (
	<div>
		<Helmet title="Technologie balení - komodity" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Technologie balení - komodity</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Balené komodity</li>
					</ul>
				</div>
				<h2>Co všechno vám můžeme zabalit?</h2>
				<p>&nbsp;</p>
				<p>Káva, luštěniny a obilniny, Cukr a sůl, Těstoviny, Kusové
				koření, Mleté koření, Práškové nápoje, Potraviny v prášku,
				Granulované instantní nápoje, Suché plody a sušené ovoce, Cukrovinky,
				Cereálie a extrudáty, Tekutiny a pasty, Petfood a krmiva pro zvířata, Prací
				prášky, detergenty, Práškové produkty, Drobné kusové zboží, Tekutiny,
			kapaliny, gely, omáčky, Granuláty, Peletky</p>
				<p><br />
				#Zrnková káva #hrách #čočka #rýže #nemleté obilniny #cukr krystal #hroznový cukr #ovocný cukr #kuchyňská sůl #mořská sůl #Krátké těstoviny různých tvarů a druhů # #pepř #paprika #curry #chilli #bobkový list #skořice #pepř celý #nové koření #hřebíček #horčice #tatarky #kečupy #energy gely #Mletá káva #kakao #cappuccino #čokoláda #sušené mléko #smetana a syrovátka #mouka #bramborová kaše #knedlíky #pudink #zmrzlina #prášky na pečení #Čaj #vitamínové #energetické nápoje #iontové nápoje #ochranné nápoje #ořechy #arašídy #mandle #strouhaný kokos #švestky #meruňky #rozinky #brusinky #rýže #luštěniny #zrní #bonbóny #balené bonbóny #želé bonbóny #dražé #lízátka #ovesné vločky #kukuřičné lupínky #popcorn #musli #petfood #ptačí zob #krmivo pro akvarijní rybičky #krmné směsi #Stavební chemie #spárové hmoty #dentální prášek #dezinfekční chemie #hnojiva #bižuterie #skleněné korálky #šroubky #puzzle #saponáty #oleje #olejové emulze #suspenze #průmyslové kapaliny #chemická hnojiva #plastové granuláty</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default BaleneKomodity;