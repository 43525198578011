const RightPanel = () => (
	<div class="right">
		<div>
			<h3><a href="/technologie-vyroba/folie/">Technologie balení - fólie</a></h3>
			<p>Permanentně antistatická fólie Běžné plastové fólie často generují elektrostatický výboj (ESD).
					Ten omezuje využitelnost těchto fólií jako obalového materiálu pro mnohé aplikace.
					&nbsp;<a href="/technologie-vyroba/folie/" class="more">více&nbsp;&raquo;</a>
			</p>
		</div>
		<div class="news-plain">
			<h3><a href="/about/#kvalita">Kvalita v našich rukách</a></h3>
			<p>
					Kvalita rozhoduje o úspěchu. Kvalitní stroje jsou zárukou vaši dlouhodobé prosperity. Precizní výroba dílů, pečlivá montáž a důsledná kontrola kvality jsou přítomny při vzniku kteréhokoliv z našich výrobků.
					&nbsp;<a href="/about/#kvalita" class="more">více&nbsp;&raquo;</a>
			</p>
		</div>
		<div class="news-plain">
			<h3><a href="/technologie-vyroba/balene-komodity/">Jaké komodity balíme?</a></h3>
			<p>
					Káva, luštěniny a obilniny, Cukr a sůl, Těstoviny, Kusové koření, Mleté koření, Práškové nápoje, Potraviny v prášku, Granulované instantní nápoje, Suché plody a sušené ovoce, Cukrovinky, Cereálie a extrudáty, Tekutiny a pasty...
				<a href="/technologie-vyroba/balene-komodity/" class="more">více&nbsp;&raquo;</a>
			</p>
		</div>
		<div class="news-plain">
			<h3><a href="/technologie-vyroba/sacky/">Balení do sáčků</a></h3>
			<p>
					Jak uchovat potraviny čerstvé, když je nespotřebujete najednou?
				<br />
					Kromě odolnějších materiálů se stále více prosazují nové technologie především v oblasti systémů opětovného uzavírání obalů. Na našich balicích strojích vyrábíme různé typy sáčků, které lze rozšířit o sekundární systémy uzavírání. Systémy opětovného uzavírání sáčků.
					&nbsp;<a href="/technologie-vyroba/sacky/" class="more">více&nbsp;&raquo;</a>
			</p>
		</div>
		<div class="news-plain">
			<h3><a href="/technologie-vyroba/davkovace/">Dávkovače...</a></h3>
			<p>
					Dávkovače jsou sice doplňkovým, ale nezbytným zařízením k balicím strojům.
				<br />
					Spolehlivě naplní zbožím sáčky, které balicí stroj zavaří nebo uzavře v horní části.
					&nbsp;<a href="/technologie-vyroba/davkovace/" class="more">více&nbsp;&raquo;</a>
			</p>
		</div>
		<div class="news-plain">
			<h3><a href="/technologie-vyroba/sacky/#baleni-do-sacku">Zapečeťte si svou pochoutku!</a></h3>
			<p>
					Uzavírání pomocí speciální etikety se aplikuje na stojací sáčky s plochým dnem typu C, F a tzv. špalíčkových sáčků typu E a F/E. V některých případech může mít etiketa i funkci pečeti. Na vertikálních balicích strojích je pomocí aplikátoru etiket možné umístit etiketu do libovolného místa sáčků. Provedení etikety je podle jejího umístění na sáček buď jednovrstvé, nebo dvouvrstvé s různou intenzitou přilnutí.
					&nbsp;<a href="/technologie-vyroba/sacky/#baleni-do-sacku" class="more">více&nbsp;&raquo;</a>
			</p>
		</div>

	</div>);
export default RightPanel;
