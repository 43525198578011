import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const Davkovace = () => (
	<div>
		<Helmet title="Dávkovače a technologie balení" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Dávkovače a technologie balení</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Dávkovače a technologie balení</li>
					</ul>
				</div>
				<h4>Dávkovače jsou sice doplňkovým, ale nezbytným zařízením
				k balicím strojům. Spolehlivě naplní zbožím sáčky, které balicí stroj
			zavaří nebo uzavře v horní části.</h4>
				<p>Dávkovače jsou šetrné k produktům, snadno nastavitelné, jednoduché na
				údržbu i čistění. Dělí se na objemové (třecí či
				klapkové), šnekové dávkovače, které jsou zvláště vhodné pro
				práškové zboží, a dávkovací váhy (vibrační, pásové a
				kombinační). K objemovým dávkovačům se také řadí
				speciální dávkovače na zelí a dávkovače láku. Dávkovače se
				dodávají jako příslušenství ke strojům na balení zboží, samostatně
				pouze ojediněle, v případě dávkování do obalů typu dóza (př. prací
			prášek), plastový kbelík, velké pytle apod.</p>
				<p>Snahou většiny výrobců vertikálních hadicových strojů je vyrábět
				balicí stroje s co největším výkonem. Dosáhnout toho lze dvěmi
			cestami.</p>
				<p>První cestou jsou kroky vedoucí k intenzivnějšímu využití
				„klasického“ jednotubusového stroje – jedná se o speciální
				konstrukci a optimalizaci pohonů jednotlivých pracovních skupin, použití
				speciálních materiálů pro výrobu některých součástí, obalových
				materiálů s krátkou dobou svařování, nových technologií apod..
				Výsledkem je pak schopnost balicího stroje vyrábět vysokou rychlostí
				sáčky. Někteří výrobci uvádějí v propagačních materiálech svých
				jednotubusových strojů výkony až 300 sáčků/min.. Otázkou ovšem
				zůstává dosažitelnost tohoto „impozantního“ výkonu na jednotubusovém
				stroji při praktickém provozu, jehož součástí je nevyhnutelně i plnění
			vyráběných sáčků baleným zbožím.</p>
				<p>S výjimkou některých případů balení kapalin, je při balení vždy
				potřebná časová mezera mezi jednotlivými dávkami, která musí být
				delší než je čas potřebný na vytvoření příčného svaru. Jinak
			dochází ke stisku baleného zboží čelistmi a znehodnocení svaru.</p>
				<p>Přestože někteří výrobci předvádějí na výstavách výkony kolem
				200 sáčků/min se zbožím, v praxi, která požaduje plynulost a
				spolehlivost provozu, se pak dosahuje skutečných dlouhodobých maximálních
			výkonů do 180 sáčků /min. / 1 tubus.</p>
				<p>Druhou cestou je použití několika paralelních tubusů na jednom balicím
				stroji. Takové stroje pak vytvářejí, plní a uzavírají několik sáčků
				současně. Přitom lze samozřejmě využít většiny úprav pro zvýšení
				výkonu, uvedených v předchozím odstavci. U vícetubusových strojů je
				ekonomičtější využití některých mechanismů a řídícího systému
				stroje, ale největší výhodou tohoto uspořádání je fakt, že při
				paralelní výrobě sáčků jsou všechny dílčí časy pracovního cyklu
				stejné jako při výrobě shodného sáčku na „klasickém“ jednotubusovém
				stroji, který však pracuje s příslušným zlomkem výkonu vícetubusového
			stroje.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default Davkovace;