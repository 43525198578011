import { h, Component } from 'preact';
import { Router } from 'preact-router';
// import { NotFoundRoute } from 'preact-router/notfoundroute';

import Header from './header';
import Footer from './footer';
import Home from '../routes/home';
import About from '../routes/about';
import Ceny from '../routes/ceny';
import TechnologieVyroba from '../routes/technologie_vyroba';
import Kontakty from '../routes/kontakty';
import UspechInspirace from '../routes/uspech_je_inspirace';
import Redirect from '../routes/redirect';
import Folie from '../routes/technologie_vyroba/folie/';
import Sl140 from '../routes/technologie_vyroba/sl140/';
import V250 from '../routes/technologie_vyroba/v250/';
import Sacky from '../routes/technologie_vyroba/sacky/';
import BaleneKomodity from '../routes/technologie_vyroba/balene_komodity/';
import BaleniPodOchrannouAtmosferou from '../routes/technologie_vyroba/baleni_pod_atmosferou/';
import Davkovace from '../routes/technologie_vyroba/davkovace/';

export default class App extends Component {

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};

	render(props) {
		return (
			<div id="app">

				<Header />
				<Router url={props.url} onChange={this.handleRoute}>
					<Home path="/" />
					<About path="/about/" />
					<TechnologieVyroba path="/technologie-vyroba/" />
					<Ceny path="/ceny/" />
					<Home path="/" />
					<Kontakty path="/kontakty/" />
					<UspechInspirace path="/s-napadem-realizovat-jej-a-uspet-je-pro-nas-inspiraci-a-vyzvou-k-dalsi-praci/" />
					<Folie path="/technologie-vyroba/folie/" />
					<Sl140 path="/technologie-vyroba/sl140/" />
					<V250 path="/technologie-vyroba/v250/" />
					<BaleneKomodity path="/technologie-vyroba/balene-komodity/" />
					<Sacky path="/technologie-vyroba/sacky/" />
					<BaleniPodOchrannouAtmosferou path="/technologie-vyroba/baleni-pod-ochrannou-atmosferou/" />
					<Davkovace path="/technologie-vyroba/davkovace/" />

					{/* <Navody exactly path="/minecraft-server-forge/" /> */}
					{/* <NotFound path="/404-not-found/" /> */}

					{/* <NotFound type="404" default /> */}
					<Redirect type="404" path="*" to="/404-not-found" default />
				</Router>
				<Footer />
			</div>
		);
	}
}
