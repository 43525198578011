import { h } from 'preact';
// import style from './style';

const Footer = () => (
	<div>
		<p class="footer_bottom_copy">Powered by
			&nbsp;<a href="http://www.rrsoft.cz/webhosting-hostingove-sluzby/" title="RRsoft - Webhostingové služby">Preact SSR</a>
			&nbsp;|&nbsp;RRsoft 2014 - 2018 &nbsp;©&nbsp; All Right Reserved</p>
	</div>

);
export default Footer;