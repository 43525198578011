import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const Sacky = () => (
	<div>
		<Helmet title="Uzavíratelné sáčky" />
		<div className="first_container">
			<div className="story">
				<div className="breadcrumb">
					<h1>Uzavíratelné sáčky</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Uzavíratelné sáčky</li>
					</ul>
				</div>
				<h4>Sáčky už zdaleka nejsou pouze o tvaru.</h4>
				<p>Kromě odolnějších materiálů se stále více prosazují nové technologie
				především v oblasti systémů opětovného uzavírání obalů. Na
				našich balicích strojích vyrábíme různé typy sáčků, které lze
				rozšířit o sekundární systémy uzavírání. Systémy opětovného
			uzavírání sáčků</p>
				<p>Jak uchovat potraviny čerstvé, když je nespotřebujete najednou? Jejich
				trvanlivost můžete prodloužit pomocí znovuuzavíratelných sáčků.
				Systémy opětovného uzavírání sáčků jsou tvořeny různými
				doplňkovými komponenty, které jsou na sáčku aplikovány a které
				umožňují po narušení nebo odstranění klasického sváru sáček
			opakovaně uzavírat.</p>
				<h3>Zapněte si chuť i vůni vašich potravin na zip!</h3>
				<h4>Zipový uzávěr je vhodný jak při balení drobných kusových, tak i sypkých a prašných produktů:</h4>
				<ul>
					<li>káva, koření</li>
					<li>suché plody</li>
					<li>snacky</li>
					<li>instantní nápoje</li>
					<li>mražené výrobky</li>
					<li>pet food – krmivo pro domácí zvířata</li>
					<li>prací prášky aj.</li>
				</ul>
				<p>Slepte příjemné s užitečným pomocí znovuuzavíratelné klopy!
				Oboustranně lepicí pásky umožní rychlý a snadný přístup k baleným
				komoditám, které si tak uchovávají původní vlastnosti. Sáčky
				s uzavíratelnou klopou jsou zvláště vhodné pro neprašné produkty,  jako
				jsou strouhaný sýr, suché plody, pet food, zrnková káva atd. Tvar sáčku
				s uzavíratelnou klopou je patentován pod označením "Easy Pack" a
			vyrábí se pouze za použití speciální fólie.</p>
				<h3 id="baleni-do-sacku">Zapečeťte si svou pochoutku!</h3>
				<p>Uzavírání pomocí speciální etikety se aplikuje na stojací sáčky
				s plochým dnem typu C, F a tzv. špalíčkových sáčků typu E a F/E.
				V některých případech může mít etiketa i funkci pečeti.
				Na vertikálních balicích strojích je pomocí aplikátoru etiket možné
				umístit etiketu do libovolného místa sáčků. Provedení etikety je podle
				jejího umístění na sáček buď jednovrstvé, nebo dvouvrstvé s různou
			intenzitou přilnutí.</p>
				<h4>Vhodné pro:</h4>
				<ul>
					<li>luštěniny</li>
					<li>rýži</li>
					<li>těstoviny</li>
					<li>suché plody</li>
					<li>pet food – krmivo pro domácí zvířata</li>
					<li>zrnkovou kávu apod.</li>
				</ul>
				<p>Na uzavírání to má paměť!</p>
				<p>Paměťová páska neboli "memory strip" je hliníková páska přivařená na vnitřní stranu fólie v horní části sáčku rovnoběžně s příčným svárem. Chrání obsah sáčku před vysypáním a uchovává
					potraviny čerstvé. Sáček se otvírá jednoduchým způsobem - odstřižením horního sváru. Pak se v místě přivařené paměťové pásky sáček přehne a páska si to "zapamatuje", tj. podrží obalový
			materiál v přehnutém stavu.</p>
				<p>Memory strip lze aplikovat na polštářkové sáčky i na sáčky se
				složeným dnem. Aplikátor paměťové pásky se zpravidla umisťuje na vertikální balicí hadicové stroje.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);

export default Sacky;