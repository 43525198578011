import './style';
import App from './components/app';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

if (typeof window !== "undefined") { 
const firebaseConfig = {
	apiKey: "AIzaSyCb-xULOj1upKx3_qz7JH6gJpov9NY4_iA",
	authDomain: "bestpackcz.firebaseapp.com",
	projectId: "bestpackcz",
	storageBucket: "bestpackcz.appspot.com",
	messagingSenderId: "820050621892",
	appId: "1:820050621892:web:7ede36c604c388dbb3a091",
	measurementId: "G-0FLHYNWM2J"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}
export default App;
