import Helmet from 'preact-helmet';
import RightPanel from '../../../components/right';

const Sl140 = () => (
	<div>
		<Helmet title="Horizontální balící stroj SL140" />
		<div class="first_container">
			<div class="story">
				<div class="breadcrumb">
					<h1>Horizontální balící stroj SL140</h1>
					<ul id="nav">
						<li><a href="/" >Home</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li><a href="/technologie-vyroba/" >Technologie a výroba</a>&nbsp;&nbsp;&raquo;&nbsp;</li>
						<li>Horizontální balící stroj SL140</li>
					</ul>
				</div>
				<p>Horizontální balicí stroje se využívají pro <a href="/technologie-vyroba/balene-komodity/">balení zboží</a> do
			stojacích sáčků typu doypack nebo plochých třístranných či
			čtyřstranných sáčků. V horizontálních balicích strojích se fólie se
			zbožím na balení pohybuje vodorovně – zleva doprava. <a	href="/technologie-vyroba/sacky/">Sáčky</a> vy­užívané
			u horizontálních balicích strojů mohou být tvarované a lze na ně pomocí
			přídavných zařízení aplikovat zipový uzávěr, šroubový uzávěr
		i brčko.</p>
				<h3>Charakteristika</h3>
				<p><strong>SL 140</strong> je první model v VOLPAK rozsahu snížit sebrat
			ještě před přenosem jej na otevření a čerpací stanice. Hlavním bodem
			tohoto systému je, že plně využívá objemovou kapacitu sáčku
			uskutečňuje, a tím optimalizuje sebrat formát v souvislosti
		s náplní dávky.</p>
				<p><strong>SL 140</strong> je k dispozici v Simplex a Duplex verzi a
			produkuje až 160 sáčků za minutu. Části vyžadující pravidelnou
			údržbu byly speciálně navrženy tak, aby provozovateli ušetřily co
			nejvíce času. Z důvodu lepší dostupnosti, lze horní těsnící svorky
		úplně složit.</p>
				<h3>Aplikace</h3>
				<p>Tento stroj je ideální pro balení vývaů, cappuccina a různých
			koření, omáček, šampónů, tekutých mýdel, rozpoštěcí prášky
			instatního pití, jemného a drobného pečiva a prášků do pečiva,
		cukrovinek, žvýkaček, kosmetických krémů a podobně.</p>
				<h3>Plniva</h3>
				<p><strong>SL 140</strong> je možné použít pro balení různých
			sortimentů a druhů výplní, jako jsou granule, prací prášky, různé
			tekutiny a pasty. <strong>SL 140</strong> lze dokonce pracovat se dvěma
			různými plnivy současně u produktů s různými komponenty, jako
		například instantní polévky.</p>
			</div>
			<RightPanel />
			<div class="clear" />
		</div>
	</div>
);
export default Sl140;